export const futureList = [
  {
    title: '开发成本低',
    desc: '小程序”跨平台的属性，开发成本和维护成本更低，开发速度更快。',
    img: require('../../images/future-1.webp'),
  },
  {
    title: '超快速响应',
    desc: '小程序页面简洁，无广告，无残留， 载入速度大幅提升。',
    img: require('../../images/future-2.webp'),
  },
  {
    title: '推广简单',
    desc:
      '无缝对接微信、APP、公众号，利用微信已有的优势，简化程序的推广流程，结合企业微信公众号的营销优势，增强用户黏性。',
    img: require('../../images/future-3.webp'),
  },
  {
    title: '用户体验好',
    desc:
      '应用无处不在，随时可用，无需安装卸载，用完即走。小程序UI和操作流程高度统一，体验更好。',
    img: require('../../images/future-4.webp'),
  },
]

export const customList = [
  {
    text: 'O2O行业',
    img: require('../../images/custom/custom-1.png'),
  },
  {
    text: '政府机构',
    img: require('../../images/custom/custom-2.png'),
  },
  {
    text: '教育机构',
    img: require('../../images/custom/custom-3.png'),
  },
  {
    text: '房产旅游',
    img: require('../../images/custom/custom-4.png'),
  },
  {
    text: '社群社交',
    img: require('../../images/custom/custom-5.png'),
  },
  {
    text: '餐饮美食',
    img: require('../../images/custom/custom-6.png'),
  },
  {
    text: '宾馆酒店',
    img: require('../../images/custom/custom-7.png'),
  },
  {
    text: '生活服务',
    img: require('../../images/custom/custom-8.png'),
  },
  {
    text: '工具应用',
    img: require('../../images/custom/custom-9.png'),
  },
  {
    text: '更多定制',
    img: require('../../images/custom/custom-10.png'),
  },
]

export const buinessList = [
  {
    title: '网站建设',
    desc: [
      '品牌展示网站',
      '官方门户网站',
      '企业营销网站',
      '电子商务平台',
      '信息门户系统',
    ],
    img: require('../../images/business/business-1.png'),
  },
  {
    title: '移动端',
    desc: ['手机网站', 'APP开发', '自适应网站', '响应式网站'],
    img: require('../../images/business/business-2.png'),
  },
  {
    title: '小程序',
    desc: ['O2O场景化', '社群社交', '生活服务', '工具应用'],
    img: require('../../images/business/business-3.png'),
  },
  {
    title: '服务号/订阅号',
    desc: ['微官网', '微商城', '微活动', '微预定', '微支付'],
    img: require('../../images/business/business-4.png'),
  },
  {
    title: '企业微信',
    desc: ['团队协作', 'CRM', '企业文化', '移动办公', '行政办公'],
    img: require('../../images/business/business-5.png'),
  },
]
