<template>
  <div class="minigrogram">
    <div class="banner1">
      <img src="../../images/minigrogram-banner1.webp" />
      <img class="btn" src="../../images/moreCaseBtn.png" @click="onClickBtn" />
    </div>
    <div class="banner2">
      <img src="../../images/minigrogram-banner2.webp" />
    </div>
    <div class="banner2"></div>
    <div class="bigFuture">
      <div class="header">
        <h2 class="title">小程序·大未来</h2>
      </div>
      <div class="list">
        <div class="item" v-for="item in futureList" :key="item.text">
          <div class="img">
            <img :src="item.img" />
          </div>
          <h3 class="title">{{ item.title }}</h3>
          <p class="desc">{{ item.desc }}</p>
        </div>
      </div>
    </div>
    <div class="custom">
      <div class="header">
        <h2 class="title">高端定制·精品小程序</h2>
      </div>
      <div class="list">
        <div class="item" v-for="item in customList" :key="item.text">
          <div class="img">
            <img :src="item.img" />
          </div>
          <h3 class="title">{{ item.text }}</h3>
        </div>
      </div>
    </div>
    <div class="business">
      <div class="header">
        <h2 class="title">业务展示</h2>
      </div>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in buinessList"
          :key="item.text"
          @click="onBuinessItem(index)"
        >
          <div class="img">
            <img :src="item.img" />
          </div>
          <h3 class="title">{{ item.title }}</h3>
          <div class="descList">
            <p class="desc" v-for="desc in item.desc" :key="desc">
              {{ desc }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="caseShow">
      <div class="header">
        <h2 class="title">案列展示</h2>
      </div>
      <div class="box">
        <m-case-box :navId="navId" />
      </div>
    </div>
    <investment></investment>
  </div>
</template>

<script>
import { futureList, customList, buinessList } from './data'
import mCaseBox from '@/components/mCaseBox'
import investment from '@/components/investment'
import { mapState } from 'vuex'
export default {
  components: {
    mCaseBox,
    investment,
  },
  data() {
    return {
      futureList,
      customList,
      buinessList,
      navId: '',
    }
  },
  created() {
    this.navId = this.$route.query.navId
  },
  computed: mapState(['menu']),
  mounted() {
    console.log(this.menu)
  },
  methods: {
    onClickBtn() {
      this.$router.push({ name: 'case', query: { navId: '' } })
    },
    onBuinessItem(index) {
      let item = ''
      if (index == 0 || index == 1) {
        // 网站开发
        item = this.menu.find((item) => item.name == 'website')
      } else if (index == 2) {
        // 小程序开发
        item = this.menu.find((item) => item.name == 'minigrogram')
      } else if (index == 3 || index == 4) {
        // 微信开发
        item = this.menu.find((item) => item.name == 'wechat')
      }
      this.$router.push({
        name: item.name,
        query: {
          navId: item.id,
        },
      })
    },
  },
}
</script>

<style lang="scss">
.minigrogram {
  .banner1,
  .banner2 {
    width: 100%;
  }
  .banner1 {
    position: relative;
    overflow: hidden;
    img {
      transition: all 0.5s;
    }
    img:hover {
      transform: scale(1.05);
    }
    .btn {
      cursor: pointer;
      position: absolute;
      width: 14%;
      bottom: 8%;
      right: 15%;
    }
  }
  .bigFuture {
    width: 100%;
    height: 673px;
    background-image: url('../../images/minigrogram-banner3.webp');
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    .header {
      padding: 78px 0 60px;
      .title {
        font-size: 44px;
        font-weight: bold;
        color: #383838;
        line-height: 54px;
        padding-bottom: 6px;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 56px;
          height: 3px;
          background: #373737;
          left: 50%;
          bottom: -5px;
          transform: translateX(-50%);
          border-radius: 2px;
        }
      }
    }
    .list {
      .item {
        width: 258px;
        height: 385px;
        display: inline-block;
        margin: 0 22px;
        box-shadow: 0px 1px 9px 0px rgba(151, 150, 153, 0.32);
        border-radius: 35px;
        background-color: #fff;
        overflow: hidden;

        .img {
          width: 100%;
        }
        .title {
          font-size: 26px;
          line-height: 36px;
          font-weight: bold;
          color: #373737;
          position: relative;
          padding-top: 20px;
          &::after {
            position: absolute;
            content: '';
            display: block;
            width: 41px;
            height: 2px;
            background: #373737;
            left: 50%;
            bottom: -5px;
            transform: translateX(-50%);
            border-radius: 1px;
          }
        }
        .desc {
          font-size: 13px;
          color: #939393;
          padding: 22px 20px 0;
        }
      }
    }
  }
  .custom {
    width: 100%;
    height: 650px;
    background-image: url('../../images/minigrogram-banner4.webp');
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    .header {
      padding: 78px 0 60px;
      .title {
        font-size: 44px;
        font-weight: bold;
        color: #383838;
        line-height: 54px;
        padding-bottom: 6px;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 56px;
          height: 3px;
          background: #373737;
          left: 50%;
          bottom: -5px;
          transform: translateX(-50%);
          border-radius: 2px;
        }
      }
    }
    .list {
      width: 920px;
      margin: 0 auto;
      .item {
        display: inline-flex;
        width: 152px;
        height: 161px;
        background-color: #f5f5f7;
        border-radius: 17px;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        flex-direction: column;
        margin: 0 15px 30px;
        .img {
          width: 80px;
          height: 80px;
        }
        .title {
          font-size: 21px;
          font-weight: 700;
          color: #3e3e3e;
        }
      }
    }
  }
  .business {
    width: 100%;
    height: 673px;
    background-image: url('../../images/minigrogram-banner3.webp');
    background-size: 100% auto;
    background-position: center center;
    background-repeat: no-repeat;
    .header {
      padding: 78px 0 60px;
      .title {
        font-size: 44px;
        font-weight: bold;
        color: #383838;
        line-height: 54px;
        padding-bottom: 6px;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 56px;
          height: 3px;
          background: #373737;
          left: 50%;
          bottom: -5px;
          transform: translateX(-50%);
          border-radius: 2px;
        }
      }
    }
    .list {
      .item {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        width: 192px;
        height: 410px;
        background: #ffffff;
        box-shadow: 0px 1px 9px 0px rgba(151, 150, 153, 0.32);
        border-radius: 17px;
        margin-right: 30px;
        cursor: pointer;
        .img {
          display: flex;
          justify-content: center;
          width: 76px;
          height: 76px;
          margin-top: 37px;
        }
        .title {
          font-size: 24px;
          font-weight: bold;
          color: #373737;
          line-height: 34px;
          margin-top: 33px;
        }
        .descList {
          font-size: 17px;
          color: #a3a3a3;
          margin-top: 18px;
          .desc {
            line-height: 34px;
          }
        }
      }
    }
  }
  .caseShow {
    padding: 0 0 58px;
    background-color: #f4f5f9;
    .header {
      padding-top: 25px;
      padding-bottom: 25px;
      background-color: #fff;
      .title {
        font-size: 44px;
        font-weight: bold;
        color: #383838;
        line-height: 54px;
        padding-bottom: 6px;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          display: block;
          width: 56px;
          height: 2px;
          background: #373737;
          left: 50%;
          bottom: -5px;
          transform: translateX(-50%);
          border-radius: 1px;
        }
      }
    }
    .box {
      padding-top: 36px;
    }
  }
}
</style>
